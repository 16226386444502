export const galleryData = [
  {
    id: 1,
    category: "arch",
    imageUrl: "/assets/gallery/1.jpg",
  },
  {
    id: 2,
    category: "arch",
    imageUrl: "/assets/gallery/2.jpg",
  },
  {
    id: 3,
    category: "arch",
    imageUrl: "/assets/gallery/3.jpg",
  },
  {
    id: 4,
    category: "arch",
    imageUrl: "/assets/gallery/4.jpg",
  },
  {
    id: 5,
    category: "arch",
    imageUrl: "/assets/gallery/5.jpg",
  },
  {
    id: 6,
    category: "arch",
    imageUrl: "/assets/gallery/6.jpg",
  },
  {
    id: 7,
    category: "arch",
    imageUrl: "/assets/gallery/7.jpeg",
  },
  {
    id: 8,
    category: "arch",
    imageUrl: "/assets/gallery/8.jpg",
  },
  {
    id: 9,
    category: "arch",
    imageUrl: "/assets/gallery/9.jpg",
  },
  {
    id: 10,
    category: "arch",
    imageUrl: "/assets/gallery/10.jpg",
  },
  {
    id: 11,
    category: "arch",
    imageUrl: "/assets/gallery/11.jpg",
  },
  {
    id: 12,
    category: "arch",
    imageUrl: "/assets/gallery/12.jpg",
  },
  {
    id: 13,
    category: "arch",
    imageUrl: "/assets/gallery/13.jpeg",
  },
  {
    id: 14,
    category: "arch",
    imageUrl: "/assets/gallery/14.jpeg",
  },
  {
    id: 15,
    category: "arch",
    imageUrl: "/assets/gallery/15.jpeg",
  },
  {
    id: 16,
    category: "arch",
    imageUrl: "/assets/gallery/16.jpg",
  },
  {
    id: 17,
    category: "arch",
    imageUrl: "/assets/gallery/17.jpg",
  },
  {
    id: 18,
    category: "arch",
    imageUrl: "/assets/gallery/18.jpg",
  },
  {
    id: 19,
    category: "arch",
    imageUrl: "/assets/gallery/19.jpg",
  },
  {
    id: 20,
    category: "arch",
    imageUrl: "/assets/gallery/20.jpg",
  },
  {
    id: 21,
    category: "arch",
    imageUrl: "/assets/gallery/21.jpg",
  },
  {
    id: 22,
    category: "arch",
    imageUrl: "/assets/gallery/22.jpg",
  },
  {
    id: 23,
    category: "arch",
    imageUrl: "/assets/gallery/23.jpg",
  },
  {
    id: 24,
    category: "arch",
    imageUrl: "/assets/gallery/24.jpg",
  },
  {
    id: 25,
    category: "arch",
    imageUrl: "/assets/gallery/25.jpg",
  },
  {
    id: 26,
    category: "arch",
    imageUrl: "/assets/gallery/26.jpg",
  },
  {
    id: 27,
    category: "arch",
    imageUrl: "/assets/gallery/27.jpg",
  },
  {
    id: 28,
    category: "arch",
    imageUrl: "/assets/gallery/28.jpg",
  },
  {
    id: 29,
    category: "arch",
    imageUrl: "/assets/gallery/29.jpg",
  },
  {
    id: 30,
    category: "arch",
    imageUrl: "/assets/gallery/30.jpg",
  },
  {
    id: 31,
    category: "arch",
    imageUrl: "/assets/gallery/31.jpg",
  },
  {
    id: 32,
    category: "arch",
    imageUrl: "/assets/gallery/32.jpg",
  },
  {
    id: 33,
    category: "arch",
    imageUrl: "/assets/gallery/33.jpg",
  },
  {
    id: 34,
    category: "arch",
    imageUrl: "/assets/gallery/34.jpg",
  },
  {
    id: 35,
    category: "arch",
    imageUrl: "/assets/gallery/35.jpg",
  },
  {
    id: 36,
    category: "arch",
    imageUrl: "/assets/gallery/36.jpg",
  },
  {
    id: 37,
    category: "arch",
    imageUrl: "/assets/gallery/37.jpg",
  },
  {
    id: 38,
    category: "arch",
    imageUrl: "/assets/gallery/38.jpg",
  },
  {
    id: 39,
    category: "arch",
    imageUrl: "/assets/gallery/39.jpeg",
  },
  {
    id: 40,
    category: "arch",
    imageUrl: "/assets/gallery/40.jpeg",
  },
  {
    id: 41,
    category: "arch",
    imageUrl: "/assets/gallery/41.jpeg",
  },
  {
    id: 42,
    category: "arch",
    imageUrl: "/assets/gallery/42.jpg",
  },
  {
    id: 43,
    category: "arch",
    imageUrl: "/assets/gallery/43.jpg",
  },
  {
    id: 44,
    category: "arch",
    imageUrl: "/assets/gallery/44.jpeg",
  },
  {
    id: 45,
    category: "arch",
    imageUrl: "/assets/gallery/45.jpg",
  },
  {
    id: 46,
    category: "arch",
    imageUrl: "/assets/gallery/46.jpg",
  },
  {
    id: 47,
    category: "arch",
    imageUrl: "/assets/gallery/47.jpg",
  },
  {
    id: 48,
    category: "arch",
    imageUrl: "/assets/gallery/48.jpg",
  },
  {
    id: 49,
    category: "arch",
    imageUrl: "/assets/gallery/49.jpg",
  },
  {
    id: 50,
    category: "arch",
    imageUrl: "/assets/gallery/50.jpg",
  },
  {
    id: 51,
    category: "arch",
    imageUrl: "/assets/gallery/51.jpg",
  },
  {
    id: 52,
    category: "photorecep",
    imageUrl: "/assets/gallery/52.jpg",
  },
  {
    id: 53,
    category: "photorecep",
    imageUrl: "/assets/gallery/53.jpg",
  },
  {
    id: 54,
    category: "photorecep",
    imageUrl: "/assets/gallery/54.jpg",
  },
  {
    id: 55,
    category: "photorecep",
    imageUrl: "/assets/gallery/55.jpg",
  },
  {
    id: 56,
    category: "photorecep",
    imageUrl: "/assets/gallery/56.jpg",
  },
  {
    id: 57,
    category: "photorecep",
    imageUrl: "/assets/gallery/57.jpeg",
  },
  {
    id: 58,
    category: "photorecep",
    imageUrl: "/assets/gallery/58.jpeg",
  },
  {
    id: 59,
    category: "photorecep",
    imageUrl: "/assets/gallery/59.jpg",
  },
  {
    id: 60,
    category: "photorecep",
    imageUrl: "/assets/gallery/60.jpg",
  },
  {
    id: 61,
    category: "photorecep",
    imageUrl: "/assets/gallery/61.jpg",
  },
  {
    id: 62,
    category: "photorecep",
    imageUrl: "/assets/gallery/62.jpg",
  },
  {
    id: 63,
    category: "photorecep",
    imageUrl: "/assets/gallery/63.jpg",
  },
  {
    id: 64,
    category: "photorecep",
    imageUrl: "/assets/gallery/64.jpg",
  },
  {
    id: 65,
    category: "photorecep",
    imageUrl: "/assets/gallery/65.jpg",
  },
  {
    id: 66,
    category: "photorecep",
    imageUrl: "/assets/gallery/66.jpg",
  },
  {
    id: 67,
    category: "photorecep",
    imageUrl: "/assets/gallery/67.jpg",
  },
  {
    id: 68,
    category: "photorecep",
    imageUrl: "/assets/gallery/68.jpg",
  },
  {
    id: 69,
    category: "photorecep",
    imageUrl: "/assets/gallery/69.jpg",
  },
  {
    id: 70,
    category: "photorecep",
    imageUrl: "/assets/gallery/70.jpg",
  },
  {
    id: 71,
    category: "photorecep",
    imageUrl: "/assets/gallery/71.jpg",
  },
  {
    id: 72,
    category: "photorecep",
    imageUrl: "/assets/gallery/72.jpg",
  },
  {
    id: 73,
    category: "photorecep",
    imageUrl: "/assets/gallery/73.jpg",
  },
  {
    id: 74,
    category: "photorecep",
    imageUrl: "/assets/gallery/74.jpg",
  },
  {
    id: 75,
    category: "photorecep",
    imageUrl: "/assets/gallery/75.jpg",
  },
  {
    id: 76,
    category: "photorecep",
    imageUrl: "/assets/gallery/76.jpg",
  },
  {
    id: 77,
    category: "photorecep",
    imageUrl: "/assets/gallery/77.jpg",
  },
  {
    id: 78,
    category: "photorecep",
    imageUrl: "/assets/gallery/78.jpg",
  },
  {
    id: 79,
    category: "photorecep",
    imageUrl: "/assets/gallery/79.jpeg",
  },
  {
    id: 80,
    category: "photorecep",
    imageUrl: "/assets/gallery/80.jpeg",
  },
  {
    id: 81,
    category: "photorecep",
    imageUrl: "/assets/gallery/81.jpeg",
  },
  {
    id: 82,
    category: "photorecep",
    imageUrl: "/assets/gallery/82.jpg",
  },
  {
    id: 83,
    category: "photorecep",
    imageUrl: "/assets/gallery/83.jpeg",
  },
  {
    id: 84,
    category: "photorecep",
    imageUrl: "/assets/gallery/84.jpeg",
  },
  {
    id: 85,
    category: "photorecep",
    imageUrl: "/assets/gallery/85.jpg",
  },
  {
    id: 86,
    category: "photorecep",
    imageUrl: "/assets/gallery/86.jpg",
  },
  {
    id: 87,
    category: "photorecep",
    imageUrl: "/assets/gallery/87.jpg",
  },
  {
    id: 88,
    category: "photorecep",
    imageUrl: "/assets/gallery/88.jpg",
  },
  {
    id: 89,
    category: "photorecep",
    imageUrl: "/assets/gallery/89.jpg",
  },
  {
    id: 90,
    category: "photorecep",
    imageUrl: "/assets/gallery/90.jpg",
  },
  {
    id: 91,
    category: "photorecep",
    imageUrl: "/assets/gallery/91.jpg",
  },
  {
    id: 92,
    category: "photorecep",
    imageUrl: "/assets/gallery/92.jpg",
  },
  {
    id: 93,
    category: "solemnization",
    imageUrl: "/assets/gallery/93.jpeg",
  },
  {
    id: 94,
    category: "solemnization",
    imageUrl: "/assets/gallery/94.jpg",
  },
  {
    id: 95,
    category: "solemnization",
    imageUrl: "/assets/gallery/95.jpg",
  },
  {
    id: 96,
    category: "solemnization",
    imageUrl: "/assets/gallery/96.jpg",
  },
  {
    id: 97,
    category: "solemnization",
    imageUrl: "/assets/gallery/97.jpg",
  },
  {
    id: 98,
    category: "solemnization",
    imageUrl: "/assets/gallery/98.jpg",
  },
  {
    id: 99,
    category: "solemnization",
    imageUrl: "/assets/gallery/99.jpg",
  },
  {
    id: 100,
    category: "solemnization",
    imageUrl: "/assets/gallery/100.jpg",
  },
  {
    id: 101,
    category: "solemnization",
    imageUrl: "/assets/gallery/101.jpg",
  },
  {
    id: 102,
    category: "solemnization",
    imageUrl: "/assets/gallery/102.jpg",
  },
  {
    id: 103,
    category: "solemnization",
    imageUrl: "/assets/gallery/103.jpg",
  },
  {
    id: 104,
    category: "solemnization",
    imageUrl: "/assets/gallery/104.jpg",
  },
  {
    id: 105,
    category: "solemnization",
    imageUrl: "/assets/gallery/105.jpg",
  },
  {
    id: 106,
    category: "solemnization",
    imageUrl: "/assets/gallery/106.jpg",
  },
  {
    id: 107,
    category: "solemnization",
    imageUrl: "/assets/gallery/107.jpg",
  },
  {
    id: 108,
    category: "solemnization",
    imageUrl: "/assets/gallery/108.jpeg",
  },
  {
    id: 109,
    category: "solemnization",
    imageUrl: "/assets/gallery/109.jpeg",
  },
  {
    id: 110,
    category: "solemnization",
    imageUrl: "/assets/gallery/110.jpg",
  },
  {
    id: 111,
    category: "solemnization",
    imageUrl: "/assets/gallery/111.jpg",
  },
  {
    id: 112,
    category: "solemnization",
    imageUrl: "/assets/gallery/112.jpg",
  },
  {
    id: 113,
    category: "photogallery",
    imageUrl: "/assets/gallery/113.jpg",
  },
  {
    id: 114,
    category: "photogallery",
    imageUrl: "/assets/gallery/114.jpg",
  },
  {
    id: 115,
    category: "photogallery",
    imageUrl: "/assets/gallery/115.jpg",
  },
  {
    id: 116,
    category: "photogallery",
    imageUrl: "/assets/gallery/116.jpg",
  },
  {
    id: 117,
    category: "photogallery",
    imageUrl: "/assets/gallery/117.jpg",
  },
  {
    id: 118,
    category: "photogallery",
    imageUrl: "/assets/gallery/118.jpg",
  },
  {
    id: 119,
    category: "photogallery",
    imageUrl: "/assets/gallery/119.jpg",
  },
  {
    id: 120,
    category: "photogallery",
    imageUrl: "/assets/gallery/120.jpg",
  },
  {
    id: 121,
    category: "photogallery",
    imageUrl: "/assets/gallery/121.jpg",
  },
  {
    id: 122,
    category: "photogallery",
    imageUrl: "/assets/gallery/122.jpg",
  },
  {
    id: 123,
    category: "photogallery",
    imageUrl: "/assets/gallery/123.jpg",
  },
  {
    id: 124,
    category: "photogallery",
    imageUrl: "/assets/gallery/124.jpg",
  },
  {
    id: 125,
    category: "photogallery",
    imageUrl: "/assets/gallery/125.jpg",
  },
  {
    id: 126,
    category: "photogallery",
    imageUrl: "/assets/gallery/126.jpg",
  },
  {
    id: 127,
    category: "photogallery",
    imageUrl: "/assets/gallery/127.jpg",
  },
  {
    id: 128,
    category: "photogallery",
    imageUrl: "/assets/gallery/128.jpg",
  },
  {
    id: 129,
    category: "photogallery",
    imageUrl: "/assets/gallery/129.jpg",
  },
  {
    id: 130,
    category: "photogallery",
    imageUrl: "/assets/gallery/130.jpg",
  },
  {
    id: 131,
    category: "faux",
    imageUrl: "/assets/gallery/131.jpg",
  },
  {
    id: 132,
    category: "faux",
    imageUrl: "/assets/gallery/132.jpg",
  },
  {
    id: 133,
    category: "faux",
    imageUrl: "/assets/gallery/133.jpg",
  },
  {
    id: 134,
    category: "faux",
    imageUrl: "/assets/gallery/134.jpg",
  },
  {
    id: 135,
    category: "faux",
    imageUrl: "/assets/gallery/135.jpg",
  },
  {
    id: 136,
    category: "faux",
    imageUrl: "/assets/gallery/136.jpg",
  },
  {
    id: 137,
    category: "faux",
    imageUrl: "/assets/gallery/137.jpg",
  },
  {
    id: 138,
    category: "faux",
    imageUrl: "/assets/gallery/138.jpg",
  },
  {
    id: 139,
    category: "faux",
    imageUrl: "/assets/gallery/139.jpg",
  },
  {
    id: 140,
    category: "faux",
    imageUrl: "/assets/gallery/140.jpg",
  },
  {
    id: 141,
    category: "faux",
    imageUrl: "/assets/gallery/141.jpg",
  },
  {
    id: 142,
    category: "faux",
    imageUrl: "/assets/gallery/142.jpg",
  },
  {
    id: 143,
    category: "faux",
    imageUrl: "/assets/gallery/143.jpg",
  },
  {
    id: 144,
    category: "faux",
    imageUrl: "/assets/gallery/144.jpg",
  },
  {
    id: 145,
    category: "faux",
    imageUrl: "/assets/gallery/145.jpg",
  },
  {
    id: 146,
    category: "faux",
    imageUrl: "/assets/gallery/146.jpg",
  },
  {
    id: 147,
    category: "faux",
    imageUrl: "/assets/gallery/147.jpg",
  },
  {
    id: 148,
    category: "faux",
    imageUrl: "/assets/gallery/148.jpg",
  },
  {
    id: 149,
    category: "faux",
    imageUrl: "/assets/gallery/149.jpg",
  },
  {
    id: 150,
    category: "faux",
    imageUrl: "/assets/gallery/150.jpg",
  },
  {
    id: 151,
    category: "faux",
    imageUrl: "/assets/gallery/151.jpg",
  },
  {
    id: 152,
    category: "faux",
    imageUrl: "/assets/gallery/152.jpg",
  },
  {
    id: 153,
    category: "faux",
    imageUrl: "/assets/gallery/153.jpg",
  },
  {
    id: 154,
    category: "faux",
    imageUrl: "/assets/gallery/154.jpg",
  },
  {
    id: 155,
    category: "faux",
    imageUrl: "/assets/gallery/155.jpg",
  },
  {
    id: 156,
    category: "faux",
    imageUrl: "/assets/gallery/156.jpg",
  },
  {
    id: 157,
    category: "faux",
    imageUrl: "/assets/gallery/157.jpg",
  },
  {
    id: 158,
    category: "faux",
    imageUrl: "/assets/gallery/158.jpg",
  },
  {
    id: 159,
    category: "faux",
    imageUrl: "/assets/gallery/159.jpg",
  },
  {
    id: 160,
    category: "faux",
    imageUrl: "/assets/gallery/160.jpg",
  },
  {
    id: 161,
    category: "faux",
    imageUrl: "/assets/gallery/161.jpg",
  },
  {
    id: 162,
    category: "faux",
    imageUrl: "/assets/gallery/162.jpg",
  },
  {
    id: 163,
    category: "faux",
    imageUrl: "/assets/gallery/163.jpg",
  },
  {
    id: 164,
    category: "faux",
    imageUrl: "/assets/gallery/164.jpg",
  },
  {
    id: 165,
    category: "faux",
    imageUrl: "/assets/gallery/165.jpg",
  },
  {
    id: 166,
    category: "faux",
    imageUrl: "/assets/gallery/166.jpg",
  },
  {
    id: 167,
    category: "faux",
    imageUrl: "/assets/gallery/167.jpg",
  },
  {
    id: 168,
    category: "faux",
    imageUrl: "/assets/gallery/168.jpg",
  },
  {
    id: 169,
    category: "faux",
    imageUrl: "/assets/gallery/169.jpg",
  },
  {
    id: 170,
    category: "faux",
    imageUrl: "/assets/gallery/170.jpg",
  },
  {
    id: 171,
    category: "faux",
    imageUrl: "/assets/gallery/171.jpg",
  },
  {
    id: 172,
    category: "faux",
    imageUrl: "/assets/gallery/172.jpg",
  },
  {
    id: 173,
    category: "faux",
    imageUrl: "/assets/gallery/173.jpg",
  },
  {
    id: 174,
    category: "faux",
    imageUrl: "/assets/gallery/174.jpg",
  },
  {
    id: 175,
    category: "faux",
    imageUrl: "/assets/gallery/175.jpg",
  },
  {
    id: 176,
    category: "faux",
    imageUrl: "/assets/gallery/176.jpg",
  },
  {
    id: 177,
    category: "faux",
    imageUrl: "/assets/gallery/177.jpg",
  },
  {
    id: 178,
    category: "faux",
    imageUrl: "/assets/gallery/178.jpg",
  },
  {
    id: 179,
    category: "faux",
    imageUrl: "/assets/gallery/179.jpg",
  },
  {
    id: 180,
    category: "faux",
    imageUrl: "/assets/gallery/180.jpg",
  },
  {
    id: 181,
    category: "faux",
    imageUrl: "/assets/gallery/181.jpg",
  },
  {
    id: 182,
    category: "faux",
    imageUrl: "/assets/gallery/182.jpg",
  },
  {
    id: 183,
    category: "faux",
    imageUrl: "/assets/gallery/183.jpg",
  },
  {
    id: 184,
    category: "faux",
    imageUrl: "/assets/gallery/184.jpg",
  },
  {
    id: 185,
    category: "faux",
    imageUrl: "/assets/gallery/185.jpg",
  },
  {
    id: 186,
    category: "fresh",
    imageUrl: "/assets/gallery/186.jpg",
  },
  {
    id: 187,
    category: "fresh",
    imageUrl: "/assets/gallery/187.jpg",
  },
  {
    id: 188,
    category: "fresh",
    imageUrl: "/assets/gallery/188.jpg",
  },
  {
    id: 189,
    category: "fresh",
    imageUrl: "/assets/gallery/189.jpg",
  },
  {
    id: 190,
    category: "fresh",
    imageUrl: "/assets/gallery/190.jpg",
  },
  {
    id: 191,
    category: "fresh",
    imageUrl: "/assets/gallery/191.jpg",
  },
  {
    id: 192,
    category: "fresh",
    imageUrl: "/assets/gallery/192.jpg",
  },
  {
    id: 193,
    category: "fresh",
    imageUrl: "/assets/gallery/193.jpg",
  },
  {
    id: 194,
    category: "fresh",
    imageUrl: "/assets/gallery/194.jpg",
  },
  {
    id: 195,
    category: "fresh",
    imageUrl: "/assets/gallery/195.jpg",
  },
  {
    id: 196,
    category: "fresh",
    imageUrl: "/assets/gallery/196.jpg",
  },
  {
    id: 197,
    category: "fresh",
    imageUrl: "/assets/gallery/197.jpg",
  },
  {
    id: 198,
    category: "fresh",
    imageUrl: "/assets/gallery/198.jpg",
  },
  {
    id: 199,
    category: "fresh",
    imageUrl: "/assets/gallery/199.jpg",
  },
  {
    id: 200,
    category: "fresh",
    imageUrl: "/assets/gallery/200.jpg",
  },
  {
    id: 201,
    category: "fresh",
    imageUrl: "/assets/gallery/201.jpg",
  },
  {
    id: 202,
    category: "fresh",
    imageUrl: "/assets/gallery/202.jpg",
  },
  {
    id: 203,
    category: "fresh",
    imageUrl: "/assets/gallery/203.jpg",
  },
  {
    id: 204,
    category: "fresh",
    imageUrl: "/assets/gallery/204.jpg",
  },
  {
    id: 205,
    category: "fresh",
    imageUrl: "/assets/gallery/205.jpg",
  },
  {
    id: 206,
    category: "fresh",
    imageUrl: "/assets/gallery/206.jpg",
  },
  {
    id: 207,
    category: "fresh",
    imageUrl: "/assets/gallery/207.jpg",
  },
  {
    id: 208,
    category: "fresh",
    imageUrl: "/assets/gallery/208.jpg",
  },
  {
    id: 209,
    category: "fresh",
    imageUrl: "/assets/gallery/209.jpg",
  },
  {
    id: 210,
    category: "fresh",
    imageUrl: "/assets/gallery/210.jpg",
  },
  {
    id: 211,
    category: "fresh",
    imageUrl: "/assets/gallery/211.jpg",
  },
  {
    id: 212,
    category: "fresh",
    imageUrl: "/assets/gallery/212.jpg",
  },
  {
    id: 213,
    category: "fresh",
    imageUrl: "/assets/gallery/213.jpg",
  },
];
