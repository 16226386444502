import LineGradient from "../components/LineGradient";
import { motion } from "framer-motion";

const Testimonials = () => {
  return (
    <section id="testimonials" className="pt-32 pb-16">
      {/* HEADING */}
      <motion.div
        className="md:w-1/3 text-center md:text-left"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
        transition={{ duration: 0.5 }}
        variants={{
          hidden: { opacity: 0, x: -50 },
          visible: { opacity: 1, x: 0 },
        }}
      >
        <p className="font-playfair font-semibold text-4xl mb-5 text-red">
          TESTIMONIALS
        </p>
        <LineGradient width="mx-auto md:mx-0 w-2/3" />
        <p className="mt-10">
          Here are some of our favourite people and the nice things they have
          said about us. There are a lot more awesome couples over at our{" "}
          <a
            className="underline decoration-double"
            href="https://www.google.com/search?q=Chris+%26+YJ+Weddings&stick=H4sIAAAAAAAA_-NgU1I1qDA2TEk0NDQwNrRMtrRMNTW1MqhITDI3MjWysDRJsjQwAKJFrMLOGUWZxQpqCpFeCuGpKSmZeenFAKfXUag_AAAA&hl=en-GB&mat=CTubjkLtGGeiElcBl7_Ll4UyilWqr7G_NY9AIXG8ejH-azw-hJBVSBjenCVd3Jf3t1nqLzprVDuj_vOeIty4ZAcFgzRFcOLZB8irt6BZLVMYh6fkMVpFBUUUn6sDyCEZVBw&authuser=0#"
            target="_blank"
          >
            {/* Put Google in their brand colours -
            https://www.brandcolorcode.com/google  */}
            Google Reviews
          </a>{" "}
          page.
        </p>
      </motion.div>

      {/* TESTIMONIALS */}
      <div className="md:flex md:justify-between gap-8">
        <motion.div
          className="mx-auto relative bg-blue max-w-[400px] h-[350px] flex flex-col justify-start py-16 px-8 mt-48
            before:absolute before:top-[-120px] before:-ml-[110px] before:left-1/2 before:content-person1"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.6 }}
          variants={{
            hidden: { opacity: 0, scale: 0.8 },
            visible: { opacity: 1, scale: 1 },
          }}
        >
          <p className="font-playfair text-6xl">“</p>
          <p className="text-center">
            Engaging Chris & YJ as our full journey wedding planner was the
            biggest blessing to us in our wedding planning journey. They were
            responsive, supportive and always eager to go the extra mile to
            achieve what we envision for the special day.
          </p>
          <p className="font-playfair text-6xl text-right">”</p>
        </motion.div>

        <motion.div
          className="mx-auto relative bg-red max-w-[400px] h-[350px] flex flex-col justify-start py-16 px-8 mt-48
            before:absolute before:top-[-120px] before:-ml-[110px] before:left-1/2 before:content-person2"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay: 0.2, duration: 0.6 }}
          variants={{
            hidden: { opacity: 0, scale: 0.8 },
            visible: { opacity: 1, scale: 1 },
          }}
        >
          <p className="font-playfair text-6xl">“</p>
          <p className="text-center">
            There really are so many options out there and I’d only really seen
            a couple of examples on there IG but I’m SO glad i went with my gut
            and took the leap of faith! Chris & YJ were able to go above and
            beyond in meeting our needs/wants of styling, budget and
            flexibility.
          </p>
          <p className="font-playfair text-6xl text-right">”</p>
        </motion.div>

        <motion.div
          className="mx-auto relative bg-yellow max-w-[400px] h-[350px] flex flex-col justify-start py-16 px-8 mt-48
            before:absolute before:top-[-120px] before:-ml-[110px] before:left-1/2 before:content-person3"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay: 0.4, duration: 0.6 }}
          variants={{
            hidden: { opacity: 0, scale: 0.8 },
            visible: { opacity: 1, scale: 1 },
          }}
        >
          <p className="font-playfair text-6xl">“</p>
          <p className="text-center">
            We are so glad that we engaged Chris and YJ for their planning,
            music and styling services for our wedding held on 27 August! They
            were always flexible, responsive, reassuring, and well-prepared.
          </p>
          <p className="font-playfair text-6xl text-right">”</p>
        </motion.div>
      </div>
    </section>
  );
};

export default Testimonials;
