import SocialMediaIcons from "../components/SocialMediaIcons";

const Footer = () => {
  return (
    <footer className="flex flex-col md:flex-row justify-around h-auto bg-red pt-10 px-10">
      <div className="flex flex-col md:w-1/3">
        <p className="md:flex justify-center md:justify-between pb-2">
          Chris & YJ Weddings was officially founded in May 2020 but the thought
          and planning behind it was established years before. We offer
          something for everyone (planning, styling, music), regardless of your
          budget and which stage of the wedding preparations you are at.
        </p>
        <div className="md:flex flex-col justify-center md:justify-between ">
          <p className="font-playfair font-semibold text-2xl text-yellow">
            Chris & YJ Weddings
          </p>
          <SocialMediaIcons />
        </div>
      </div>
      <div className="md:w-1/3 basis-1/2 flex justify-center ">
        <img
          src="assets/planning-cover-photo.jpg"
          alt=""
          className="z-10 w-full md:max-h-[240px] max-w-[360px]"
        />
      </div>
    </footer>
  );
};

export default Footer;
