import LightGallery from "lightgallery/react";
import { galleryData } from "../galleryData";

// import styles
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";
import "lightgallery/css/lg-fullscreen.css";
import "lightgallery/css/lg-share.css";

// import plugins if you need
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";
import lgAutoplay from "lightgallery/plugins/autoplay";
import lgFullscreen from "lightgallery/plugins/fullscreen";
import lgShare from "lightgallery/plugins/share";

import LineGradient from "../components/LineGradient";
import { motion } from "framer-motion";

function Examples() {
  const onInit = () => {};

  return (
    <div className="App pt-10 pb-24" id="examples">
      <motion.div
        className="md:w-1/3"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
        transition={{ duration: 0.5 }}
        variants={{
          hidden: { opacity: 0, x: -50 },
          visible: { opacity: 1, x: 0 },
        }}
      >
        <p className="font-playfair font-semibold text-4xl mb-5 ">
          PAST <span className="text-red">WORKS</span>
        </p>
        <LineGradient width="w-2/3" />
        <p className="mt-10 mb-7">
          There are more than two hundred photos here so feel free to keep
          scrolling through until you find something you like.
          <br></br>
          <br></br>
          Nothing inspiring you? Don't fret, remember that all of our florals
          are customised to fit your desired theme. After all, every wedding is
          different and special in its own way.
        </p>
      </motion.div>
      <div className="m-10"></div>
      <LineGradient />
      <p className="font-playfair flex flex-row justify-center mt-10 mb-7 text-2xl ">
        Faux Floral Arches & Backdrops
      </p>
      <LineGradient />
      <div className="m-10"></div>
      <LightGallery
        onInit={onInit}
        speed={500}
        plugins={[lgThumbnail, lgZoom, lgAutoplay, lgFullscreen, lgShare]}
      >
        {galleryData.map((image, index) => {
          if (image.category === "arch") {
            return (
              <a href={image.imageUrl} key={image.id}>
                <img alt={image.alt} src={image.imageUrl} />
              </a>
            );
          }
        })}
      </LightGallery>
      <div className="m-10"></div>
      <LineGradient />
      <p className="font-playfair flex flex-row justify-center mt-10 mb-7 text-2xl ">
        Photo/Reception Tables
      </p>
      <LineGradient />
      <div className="m-10"></div>
      <LightGallery
        onInit={onInit}
        speed={500}
        plugins={[lgThumbnail, lgZoom, lgAutoplay, lgFullscreen, lgShare]}
      >
        {galleryData.map((image, index) => {
          if (image.category === "photorecep") {
            return (
              <a href={image.imageUrl} key={image.id}>
                <img alt={image.alt} src={image.imageUrl} />
              </a>
            );
          }
        })}
      </LightGallery>
      <div className="m-10"></div>
      <LineGradient />
      <p className="font-playfair flex flex-row justify-center mt-10 mb-7 text-2xl ">
        Solemnization Table & Chairs
      </p>
      <LineGradient />
      <div className="m-10"></div>
      <LightGallery
        onInit={onInit}
        speed={500}
        plugins={[lgThumbnail, lgZoom, lgAutoplay, lgFullscreen, lgShare]}
      >
        {galleryData.map((image, index) => {
          if (image.category === "solemnization") {
            return (
              <a href={image.imageUrl} key={image.id}>
                <img alt={image.alt} src={image.imageUrl} />
              </a>
            );
          }
        })}
      </LightGallery>

      <div className="m-10"></div>
      <LineGradient />
      <p className="font-playfair flex flex-row justify-center mt-10 mb-7 text-2xl ">
        Photo Galleries
      </p>
      <LineGradient />
      <div className="m-10"></div>
      <LightGallery
        onInit={onInit}
        speed={500}
        plugins={[lgThumbnail, lgZoom, lgAutoplay, lgFullscreen, lgShare]}
      >
        {galleryData.map((image, index) => {
          if (image.category === "photogallery") {
            return (
              <a href={image.imageUrl} key={image.id}>
                <img alt={image.alt} src={image.imageUrl} />
              </a>
            );
          }
        })}
      </LightGallery>
      <div className="m-10"></div>
      <LineGradient />
      <p className="font-playfair flex flex-row justify-center mt-10 mb-7 text-2xl ">
        Other Faux Florals
      </p>
      <LineGradient />
      <div className="m-10"></div>
      <LightGallery
        onInit={onInit}
        speed={500}
        plugins={[lgThumbnail, lgZoom, lgAutoplay, lgFullscreen, lgShare]}
      >
        {galleryData.map((image, index) => {
          if (image.category === "faux") {
            return (
              <a href={image.imageUrl} key={image.id}>
                <img alt={image.alt} src={image.imageUrl} />
              </a>
            );
          }
        })}
      </LightGallery>
      <div className="m-10"></div>
      <LineGradient />
      <p className="font-playfair flex flex-row justify-center mt-10 mb-7 text-2xl ">
        Fresh Florals
      </p>
      <LineGradient />
      <div className="m-10"></div>
      <LightGallery
        onInit={onInit}
        speed={500}
        plugins={[lgThumbnail, lgZoom, lgAutoplay, lgFullscreen, lgShare]}
      >
        {galleryData.map((image, index) => {
          if (image.category === "fresh") {
            return (
              <a href={image.imageUrl} key={image.id}>
                <img alt={image.alt} src={image.imageUrl} />
              </a>
            );
          }
        })}
      </LightGallery>
    </div>
  );
}

export default Examples;
