import LineGradient from "../components/LineGradient";
import useMediaQuery from "../hooks/useMediaQuery";
import { motion } from "framer-motion";

const OurServices = () => {
  const isAboveMediumScreens = useMediaQuery("(min-width: 1060px)");

  return (
    <section id="services" className="pt-10 pb-24">
      {/* HEADER AND IMAGE SECTION */}
      <div className="md:flex md:justify-between md:gap-16 mt-32">
        <motion.div
          className="md:w-1/3"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 },
          }}
        >
          <p className="font-playfair font-semibold text-4xl mb-4 ">
            OUR <span className="text-red">SERVICES</span>
          </p>
          <LineGradient width="w-2/3" />
          <p className="mt-7 mb-8">
            Most of our couples engage us for either wedding planning or wedding
            styling. Some couples hire us for both. Others have asked Chris to
            be their acoustic wedding singer. We've also had corporations hire
            us and venues partner with us.
            <br></br>
            <br></br>
            Every client has unique tastes and are looking for different vibes
            to accompany their event. For some, we are the thoughtful wedding
            planner they are looking for. For others, we offer high quality
            decor at low affordable prices.
            <br></br>
            <br></br>
            Regardless of which service you hire us for, we guarantee the same
            hardworking attention to detail that has become our ethos. We
            promise you, there's nothing we want more than a very satisfied
            bride & groom.
          </p>
        </motion.div>

        <div className="mt-16 md:mt-0">
          {isAboveMediumScreens ? (
            <div
              className="relative z-0 ml-20 before:absolute before:-top-10 before:-left-10
              before:w-full before:h-full before:border-2 before:border-blue before:z-[-1]"
            >
              <img
                alt="services"
                className="z-10 w-full max-w-[400px] md:max-w-[600px]"
                src="assets/chris-the-stylist.jpg"
              />
            </div>
          ) : (
            <img
              alt="skills"
              className="z-10"
              src="assets/chris-the-stylist.jpg"
            />
          )}
        </div>
      </div>

      {/* SERVICES */}
      <div className="md:flex md:justify-between mt-8 gap-32">
        {/* Wedding PLANNING */}
        <motion.div
          className="md:w-1/3 mt-10"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
          }}
        >
          <div className="relative h-32">
            <div className="z-10">
              <p className="font-playfair font-semibold text-5xl">01</p>
              <p className="font-playfair font-semibold text-3xl mt-3">
                Wedding PLANNING
              </p>
            </div>
            <div className="w-1/2 md:w-3/4 h-32 bg-blue absolute right-0 top-0 z-[-1]" />
          </div>
          <p className="mt-5">
            We have two planning packages for couples who require different
            levels of assistance along their journey. Actual day coordination
            and full wedding planning. Schedule a Zoom call with us to discover
            which package is more worth it for you.
          </p>
        </motion.div>

        {/* Wedding STYLING */}
        <motion.div
          className="md:w-1/3 mt-10"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay: 0.2, duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
          }}
        >
          <div className="relative h-32">
            <div className="z-10">
              <p className="font-playfair font-semibold text-5xl">02</p>
              <p className="font-playfair font-semibold text-3xl mt-3">
                Wedding STYLING
              </p>
            </div>
            <div className="w-1/2 md:w-3/4 h-32 bg-red absolute right-0 top-0 z-[-1]" />
          </div>
          <p className="mt-5">
            We have a wide selection of arches and backdrops, with accent
            decorations that elevate the styling. All of our florals are
            customised so you can get the exact look that you desire. Take a
            look at our rate card or ask our team for a customized quote.
          </p>
        </motion.div>
        {/* MORE SERVICES */}
        <motion.div
          className="md:w-1/3 mt-10"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay: 0.4, duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
          }}
        >
          <div className="relative h-32">
            <div className="z-10">
              <p className="font-playfair font-semibold text-5xl">03</p>
              <p className="font-playfair font-semibold text-3xl mt-3">
                And MORE
              </p>
            </div>
            <div className="w-1/2 md:w-3/4 h-32 bg-yellow absolute right-0 top-0 z-[-1]" />
          </div>
          <p className="mt-5">
            From wedding signs to wedding singers, there's always a way for us
            to help out on your big day. Take the pressure off your friends and
            yourselves by getting in touch with us today. Corporate enquiries
            are also welcome.
          </p>
        </motion.div>
      </div>
    </section>
  );
};
export default OurServices;
